var site = site || {};

site.window = {
	scrollPosition: 0
};

site.device = {
	// Check if device is a touch device
	isTouch: function() {
		try {
			document.createEvent("TouchEvent");
			return true;
		} catch (e) {
			return false;
		}
	}
};


// A few layout methods
site.layout = {
	// Wrap elements into a div.row
	rowWrap: function(selectorBegin, selectorEnd, extraClasses) {
		if (selectorEnd == undefined) {
			selectorEnd = selectorBegin;
		}

		if (extraClasses == undefined) {
			extraClasses = '';
		}

		$(selectorBegin).nextUntil(selectorEnd).andSelf().add(selectorEnd)
			.wrapAll("<div class='row " +extraClasses+ "'></div>");
	}
};

site.divisions = {
	init: function () {
		var $divisions = $('#divisions');
		$divisions.click(function () {
			$(this).toggleClass('open');
		});

		$('#divisions a').click(function (e) {
			if (!$divisions.hasClass('open')) {
				e.preventDefault();
				e.stopPropagation();
				$divisions.addClass('open');
				$('body').addClass('division-open');
			}
		});
		$('body').click(function () {
			if($(this).hasClass('division-open')) {
				$(this).removeClass('division-open');
				$divisions.removeClass('open');
			}
		})
	}
};


site.owl = {
	// Shorthand to init owlcarousel
	init: function(id, options) {
		var $owl = $(id);
		if ($owl.length > 0) {
			$owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
			$owl.owlCarousel(options);
		}
	}
};

site.nav = {
	// Init navigation scripts
	init: function() {
		// Add expand link to mobile menu
		//$('.children', '#mMenu ').prepend('<a class="expand" href="#"><span>+</span></a>');

		site.nav.bind();
		site.nav.appendLanguage();
	},
	bind: function() {
		// Mobile menu toggle
		$('#mainmenuToggle').click(function(e) {
			var menuOpen = $('body').hasClass('mainmenu-open');
			site.nav.mobileToggle(e, menuOpen, site.window.scrollPosition);
			e.preventDefault();
		});

		// Expand in mobile menu
		$('.expand', '#mMenu').bind('click', function(e) {
			e.preventDefault();

			var $this = $(this);
			var $ul = $this.siblings('ul');

			$ul.toggleClass('hide');
			$this.next().toggleClass('down');
			$this.toggleClass('down');
			e.preventDefault();
		});
	},
	// Toggle mobile menu
	mobileToggle: function(e, menuOpen, scrollPosition) {
		$('body').toggleClass('mainmenu-open');
		$(this).toggleClass('open');
		if (!menuOpen) {
			$('i', '#mainmenuToggle').removeClass('fa-bars').addClass('fa-close');
		}
		else {
			$('i', '#mainmenuToggle').removeClass('fa-close').addClass('fa-bars');
		}
	},
	// Append language to mobilemenu
	appendLanguage: function() {
		var $divisions = $('.menu', '#divisions');
		var $menu = $('ul.mmLevel1', '#mainmenu');

		var $html = $('<li class="ele-language si level1"></li>');
		$divisions.find('li').each(function() {
			var $li = $(this).clone();
			$html.append($li.find('a'));
		});

		$menu.append($html);
	}
};

site.banner = {
	bannerText: $('.wrapper', '#owl-hero'),
	bannerDownWrap: $('.down', '#owl-hero')
};

// Run all the inits \o/
$(function() {
	boldLast('.boxTitle h1');

	site.nav.init();

	site.owl.init('#owl-hero', {
		loop:false,
		nav:true,
		navText:["",""],
		items:1,
		dots: false,
		autoplay: false,
		autoplayTimeout: 10000,
		animateOut:'fadeOut',
		animateIn:'fadeIn'
	});

	firstPattern = $('.first-pattern');
	body = $('body');

	$(window).scroll(function (e) {
		scrollPos = $(this).scrollTop();

		if (scrollPos > 40) {
			body.addClass('stickyHead');
			site.banner.bannerDownWrap.addClass('away');
		} else {
			body.removeClass('stickyHead');
			site.banner.bannerDownWrap.removeClass('away');
		}
		site.banner.bannerText.css({
			'padding-bottom' : ( scrollPos / 2 ) + "px",
			'opacity' : 1 - ( scrollPos / 250 )
		});
		$('.pattern').css({
			'margin-top' : ( scrollPos / 1.5 ) + "px"
		});
	});


	// Responsive vimeo player
	//var $i = $('iframe', '#center');
	//if ($i.length > 0 && $i.attr('src').indexOf('player.vimeo.com') != -1) {
	//	$i.wrap('<div class="vimeoPlayer"></div>');
	//}

	// Responsive tables
	$('table', '#center').wrap('<div class="responsiveTable"></div>');

	// Touch login
	$('.login').bind('click', function(e) {
		e.preventDefault();
		window.location = '/moya';
	});

	$hero = $('#owl-hero');
	$hero.find('.down a ').click(function(e) {
		e.preventDefault();
		$('html, body').animate({scrollTop:$('#owl-hero').height()}, 'slow');
	});

	// Reorder language menu based on selected
	// Move is to the top
	//$li = $('.selected', '#divisions');
	//$ul = $('.menu', '#divisions');
	//$ul.prepend($li);

	site.divisions.init();
});

boldLast = function(selector) {
	$(selector).each(function() {
		var words = $(this);
		var word_array, last_word, first_part;

		word_array = words.html().split(/\s+/); // split on spaces
		last_word = word_array.pop();             // pop the last word
		first_part = word_array.join(' ');        // rejoin the first words together

		words.html([first_part, ' <span class="last-word">', last_word, '</span>'].join(''));
	});
};
